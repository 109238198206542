import React from 'react';
import './App.css';
import Logo from './components/logo'
import Textcentre from './components/test'
import AlternateReverseTimeline from './components/timeline'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import BackgroundComponent from './components/BackgroundComponent'
import FooterComponent from './components/paragraph'
function App() {
  return (
    <div className="App">
      <div>
      <AlternateReverseTimeline />
      <BackgroundComponent />
      <Logo />
      </div>
      <FooterComponent/>
    </div>
   
  );
}

const Header = () => (
  <header className="App-header">
    <h1 className="logo">XNimbus</h1>
    <nav>
      <ul>
        <li><a href="#intro">Home</a></li>
        <li><a href="#features">Features</a></li>
      </ul>
    </nav>
  </header>
);

const Introduction = () => (
  <section id="intro" className="intro">
    <h2>Welcome to XNimbus</h2>
    <p>Your ultimate solution for seamless cloud integration and management.</p>
  </section>
);

const Features = () => (
  <section id="features" className="features">
    <h2>Features</h2>
    <ul>
      <li>Easy Cloud Integration</li>
      <li>Scalable Infrastructure</li>
      <li>Robust Security</li>
      <li>24/7 Support</li>
    </ul>
  </section>
);

const DevelopmentNotice = () => (
  <section id="development" className="development">
    <h2>We're Still in Development!</h2>
    <p>Our team is working hard to bring you the best experience possible. Stay tuned for updates!</p>

    <p>For inquiries, reach out to us at <a href="mailto:chinmoy@xnimbus.com">info@xnimbus.com</a>.</p>
    </section>
  );
  
  const Footer = () => (
    <footer className="App-footer">
      <p>&copy; 2024 XNimbus. All rights reserved.</p>
    </footer>
  );
  
  export default App;
  