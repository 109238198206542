import './NimbusSVG.css'

function Logo() {
    const styles = {
        container: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        },
      };
    return (
        <div className="container">
      <svg width="250" height="150" viewBox="0 0 250 150" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <image width="250" height="150" id="img1" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeAAAAFoAQMAAAC1+bpjAAAAAXNSR0IB2cksfwAAAANQTFRF////p8QbyAAAAFpJREFUeJztyzENAAAMA6DVv+mJ6NfAT64QWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZZlWZbl/fzU0AFpZ9p5EAAAAABJRU5ErkJggg=="/>
        </defs>
        <style>
          {`.s0 { fill: #00f0b5 }
            .s1 { fill: #616866 }`}
        </style>
        <use id="Background" style={{ display: 'none' }} href="#img1" x="-141" y="-160"/>
        <path id="x" className="s0" aria-label="x" d="m28.8 40l8.3 14.3c1.9 3.2 3.7 5.1 6.7 5.1 4.6 0 7.3-3.5 7.3-7.3 0-2.2-0.6-3.5-1.4-4.7l-11.2-17.9 10.5-16.7c0.9-1.4 1.5-3 1.5-5.3 0-3.5-3.2-6.7-6.5-6.7-2.3 0-4.5 0.7-6.7 4.6l-7.8 13.8-8.1-14.4c-1.7-3-3.6-4-5.9-4-5.2 0-7.7 4.5-7.7 7.8 0 1.5 0.7 2.9 1.5 4.1l10.4 16.8-11.1 17.6c-1.3 2-2 3.6-2 5 0 4.3 3.6 7.3 7 7.3 2.7 0 4.7-1.5 5.7-3.1z"/>
        <path id="nimbus." className="s1" aria-label="nimbus." d="m53.3 41.6c0 1.6 0.7 2.9 2.9 2.9 2.3 0 3-1.3 3-2.9v-9.6c0-3 2.4-4.6 4.4-4.6 2.5 0 3.7 1.6 3.7 4v10.2c0 1.6 0.6 2.9 2.9 2.9 2.3 0 2.9-1.3 2.9-2.9v-11.4c0-5.9-3.8-7.6-7.3-7.6-3.4 0-5.5 1.5-7.1 3.6h-0.1v-0.7c0-1.8-0.8-2.9-2.6-2.9-1.9 0-2.7 1.1-2.7 2.9zm24.8 0c0 1.5 0.6 2.9 2.9 2.9 2.3 0 2.9-1.4 2.9-2.9v-16.1c0-1.6-0.6-3-2.9-3-2.3 0-2.9 1.4-2.9 3zm-0.1-24.1c0 1.7 1.3 3.1 3 3.1 1.7 0 3-1.4 3-3.1 0-1.7-1.3-3-3-3-1.7 0-3 1.3-3 3zm10.9 24.1c0 1.5 0.6 2.9 2.9 2.9 2.3 0 2.9-1.4 2.9-2.9v-9.8c0-2.7 2-4.5 4.3-4.5 2.2 0 3.2 1.7 3.2 3.8v10.5c0 1.5 0.6 2.9 2.9 2.9 2.3 0 2.9-1.4 2.9-2.9v-9.8c0-2.7 2-4.5 4.2-4.5 2.3 0 3.2 1.7 3.2 3.8v10.5c0 1.5 0.7 2.9 3 2.9 2.2 0 2.9-1.4 2.9-2.9v-12c0-5.2-3.3-7.1-7.3-7.1-3.2 0-5.2 1.4-6.9 3.7-1.1-2.8-3.6-3.7-5.6-3.7-2.9 0-5.5 1.4-7 3.7h-0.1v-0.8c0-1.7-1-2.9-2.8-2.9-1.7 0-2.7 1.2-2.7 2.9zm37 0.2c0 1.8 0.9 2.8 2.7 2.8 1.7 0 2.6-1 2.6-2.8v-0.9h0.1c0.9 2.5 3.3 3.7 6.1 3.7 4.9 0 9.2-3.6 9.2-11.2 0-5.6-2.8-10.8-9-10.8-2.6 0-4.6 1.1-5.8 3v-8c0-1.9-1.2-2.9-3-2.9-1.7 0-2.9 1-2.9 2.9zm14.9-8.1c0 3.4-1.6 6.3-4.7 6.3-3.4 0-4.7-3-4.7-6.3 0-3 1.2-6.5 4.7-6.5 3.7 0 4.7 3.8 4.7 6.5zm29.4-8.1c0-1.6-0.7-3-3-3-2.2 0-2.9 1.4-2.9 3v9.6c0 2.9-2.4 4.6-4.4 4.6-2.5 0-3.7-1.7-3.7-4v-10.2c0-1.6-0.6-3-2.9-3-2.3 0-2.9 1.4-2.9 3v11.3c0 5.9 3.8 7.7 7.3 7.7 3.3 0 5.5-1.5 7.1-3.7h0.1v0.8c0 1.7 0.8 2.9 2.6 2.9 1.9 0 2.7-1.2 2.7-2.9zm3.7 3.6c0 3.9 2.6 5.3 6 6.1l3.5 0.9c2 0.5 3.3 0.7 3.3 2.2 0 1.2-1.3 2.1-3.4 2.1-4.3 0-4.5-3.6-7.1-3.6-1.6 0-2.3 1.2-2.3 2.5 0 2.9 4.4 5.2 9.7 5.2 4.4 0 9-2.2 9-6.9 0-4.1-4-5.5-7.3-6.3l-2.5-0.5c-1.8-0.4-3.1-0.8-3.1-2.1 0-1.3 1.3-1.8 3.4-1.8 3.7 0 3.7 2.7 6 2.7 1.5 0 2.5-1.2 2.5-2.6 0-2.7-4.5-4.4-9-4.4-4 0-8.7 1.7-8.7 6.5zm22.9 11.9c0 1.8 1.5 3.3 3.3 3.3 1.8 0 3.3-1.5 3.3-3.3 0-1.8-1.5-3.3-3.3-3.3-1.8 0-3.3 1.5-3.3 3.3z"/>
      </svg>
        <div className='textp'>
        <h1 className='insidet'>
        At XNimbus, we offer top-notch web development services. We are a team of passionate developers dedicated to bringing your vision to life.
        </h1>
        </div>
      </div>
      
    
    )

  
  }
  
  export default Logo
  