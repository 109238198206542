import React from 'react';
import './FooterComponent.css';

const FooterComponent = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} XNimbus. All rights reserved. info@xnimbus.com</p>
      </div>
    </footer>
  );
};

export default FooterComponent;
